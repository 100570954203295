.project-details-container {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    @media (max-width: 900px) {
        flex-direction: column;
    }
}

.project-infos {
    text-align: justify;
    font-size: 18px;
    width: 100%;
    margin-bottom: 50px;
}

.project-image-list {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 900px) {
        align-items: center;
    }
}

.project-image {
    margin-bottom: 25px;
    width: 400px;
    height: 300px;
    box-shadow: 3px 3px 14px 0px rgba(0, 0, 0, 0.25);



    @media (max-width: 900px) {
        width: 300px;
        height: 200px;
    }
}

.tag-list {
    position: inherit;
    display: flex;
    bottom: 15%;
    font-size: 16px;
  }
  
.tag {
    border: 1px solid #075387;
    border-radius: 45px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
}

.breadcrumb {
    display: flex;
    justify-content: flex-start;
    // opacity: 0.5;
    color: black;
    font-weight: 400;
    opacity: 0.5;
    font-size: 18px;
    
    a:visited {
        color: black;
    }
}

.redirect-link {
    margin-right: 15px;

    @media (max-width: 900px) {
        margin: 5px;
    }
}

.project-description {
    white-space: pre-wrap;
}

.redirect-button-list {
    display: flex;

    @media (max-width: 900px) {
        justify-content: center;
    }
    
    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }
}

.project-image-list-header {
    display: none;
    @media (max-width: 900px) {
        display: block;
    }
}