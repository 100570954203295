.about-headline {
    display: flex;
    text-align: start;
    margin-top: 60px;

    @media (max-width: 1400px) {

        display: block;
    }
}

.about-historic {
    display: flex;
    flex-direction: column;
}

.about-category {
    margin-top: 50px;
}

.icon-container {
    min-width: 80px;
    margin-right: 15px;
}

.timeline-icon {
    right: 0px;
    min-width: 80px;
    max-width: 80px;
    font-size: 70px;
    color: #007db8;
    // color: #37BBBD;
    height: auto;
    z-index: 2;
    // margin-right: 15px;
}

.timeline-dot {
    min-width: 80px;
    font-size: 40px;
    margin-right: 15px;
}

.about-list {
    padding: 0px;

}

.timeline-item {
    display: flex;
    margin-bottom: 55px;
}

.timeline-description{
    display: flex;
    flex-direction: column;
}

.timeline-title {
    font-weight: 600;
}

.timeline-date {
    font-size: 18px;
    line-height: 22px;
}

.languages-section {
    display: flex;
}

.languages-col {
    margin: 12px;
    display: flex;
    flex-direction: column;
    width: 50%;
}

.about-picture-container {
    @media (max-width: 900px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.about-picture {
    margin-right: 80px; 
    margin-bottom: 25px;

    @media (max-width: 1400px) {
        margin-left: 80px;
    }
}

.about-redirect-link {
    margin: 0px;
    
    a:link {
        color: #075387;
        text-decoration: underline;
    }

    a:visited {
        color: #075387;
        text-decoration: underline;
    }
}

.about-text-container{
    @media (max-width: 800px) {
        text-align: justify;
    }
}

.about-text {
    margin: 0px;
}