.footer-section {
    color: #fafafad1;
    font-size: 14px;
}

.footer-background {
    width: 100%;
    height: 40px;
    left: -20%;
    margin-left: 20%;
    position: absolute;
    z-index: 0;
}