.technologies-list {
  padding: 0px;
  margin-top: 50px;
  margin-bottom: 70px;
  text-align: left;
  display: flex;
  list-style-type: none;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 70px;

  @media (max-width: 550px) {
    flex-direction: column;
    margin-top: 25px;
    margin-bottom: 45px;
  }
}

.technologies-item{
  width: 33%;
  padding-right: 50px;
  display: flex;
  flex-direction: column;

  @media (max-width: 550px) {
    width: 100%;
    padding-right: 0px;
    margin-top: 10px;
  }
}

.technologies-icons{
  font-size: 45px;

  @media (max-width: 550px) {
    font-size: 32px;
  }
}

.technology-icon {
  margin-right: 8px;
}