.contact-page {
    height: 100%;
}

.contact-text {
    text-align: left;
}

.contact-container {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.contact-links {
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: flex-start;
    justify-content: center;
}

.contact {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}

.contact-icon {
    font-size: 50px;
    margin-right: 15px;

    @media (max-width: 800px) {
        font-size: 36px;
    }
}

.contact-text {
    font-size: 20px;
}