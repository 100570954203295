.App {
  text-align: center;
  width: 100%;
  height: 100%;
  // margin-bottom: 50px;
  font-size: 20px;
  line-height: 40px;
  min-height: 100vh;

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 28px;
  }
}


.page-margin{
  top: 0px;
  margin: var(--page-margin);
}

.page-padding {
  padding-bottom: 40px;
}

a:link, a:visited {
  text-decoration: none;
}

.primary-button {
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  margin-right: 25px;
  box-shadow: 2px 2px 5px #00000085;
  color:white;
  font-size: 18px;
  width: fit-content;
  white-space: nowrap;
  border: 0px;
  cursor: pointer;

  a:link {
    color: white;
  }

  a:visited {
    color: white;
  }

  @media (max-width: 800px) {
    font-size: 14px;
  }
}

.animation-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #52aea2;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.start-animation {
  animation: App-logo-animation .5s linear 3s forwards;
}


@keyframes App-logo-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: -10;
    display: none;
  }
}

.logo-gif {
  width: 40%;

  @media (max-width: 900px) {
    width: 90%;
  }
}



.profile-photo-container {
  float: right;
  width: 350px;
  height: 350px;
  border-radius: 10px;
  position:relative;

  @media (max-width: 900px) {
    border-radius: 100%;
    width: 250px;
    height: 250px;
    float: none;
    margin-left: 0px;
  }

  @media (max-width: 450px) {
    width: 180px;
    height: 180px;
  }

  .profile-photo-img {
    border-radius: 500px;
    width: 350px;
    height: 350px;
    object-fit: cover;
    object-position: top;
    box-shadow: 2px 2px 10px #00000047;

    @media (max-width: 900px) {
      border-radius: 100%;
      width: 250px;
      height: 250px;
    }

    @media (max-width: 450px) {
      width: 180px;
      height: 180px;
    }
  }
  
  // &:hover{
  //   transition: .5s ease-out;
  //   filter: brightness(95%) grayscale(40%) contrast(160%) saturate(250%);
    
  //   &:before{
  //     content:'';
  //     position:absolute;
  //     left:0; top:0;
  //     width:100%; height:100%;
  //     display:inline-block;
  //     border-radius: 10px;
  //     background:  rgba(55,187,189,0.35);
  //     transition: .5s ease-out; 
  //     @media (max-width: 900px) {
  //       border-radius: 100%;
  //     }
  //   }
  // }
  
  // &:not(:hover) {
  //   transition: .5s ease-out;
  //   &:before {
  //     content:'';
  //     position:absolute;
  //     left:0; top:0;
  //     width:100%; height:100%;
  //     display:inline-block;
  //     border-radius: 10px;
  //     background: rgba(55,187,189,0);
  //     transition: .5s ease-out; 

  //     @media (max-width: 900px) {
  //       border-radius: 100%;
  //     }
  //   }
  // }
}

.center-container {
  display: flex;
  justify-content: center;
}

.turquoise {
  color: #37BBBD;
}

.black {
  color: black;
}

.section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.align-left{
  text-align: left;
}

.normal-text {
  line-height: 2.5rem;
}


@media (max-width: 800px) {
  .profile-photo-img {
    border-radius: 100%;
  }
}