.project-card {
  width: fit-content;
}

.project-card-link {
  display: flex;
  width: fit-content;
}

.thumbnail {
    width:400px;
    height: 300px;
    border-radius: 10px;
    object-fit: cover;
    transition: 1.5s ease-out;
    filter:saturate(0.8);

    @media (max-width: 900px) {
      width:140px;
      height: 120px;
      border-radius: 5px;
    }
}

.gradient-background{
    position:relative;
    // display:inline-block;
    overflow: hidden;
    display: flex;
    height: fit-content;
    width: fit-content;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    // border-radius: 10px;
    @media (max-width: 900px) {
      flex-direction: column;
      width:140px;
      border-radius: 5px;
      box-shadow: none;
    }
}

.gradient-background:after {
  content:'';
  position:absolute;
  left: 0; 
  bottom: 0px;
  width:100%; 
  height:18%;
  display:inline-block;
  border-radius: 0px 0px 10px 10px;
  // box-shadow: 0 1000px rgba(5, 244, 252, 0.13) inset;
  //background: linear-gradient(180deg, rgba(55,187,189, 0) 0%, rgba(55,187,189, 0.041) 60%, rgba(55,187,189, 0.60) 80%, rgba(55,187,189,1) 100%);
  // background: linear-gradient(145deg, #2e8ad7, #0f3c52);
  background: var(--project-card-banner-background-color);
  transition: background 0.5s ease-out;  

  @media (max-width: 900px) {
    width:140px;
    height: 120px;
    border-radius: 0px;
    background: none;
  }

}
  
.card-text {
  text-align: justify;
  text-justify: auto;
  font-weight: 500;
  margin-left: 5%;
  margin-right: 5%;
  z-index: 1;

  @media (max-width: 900px) {
    margin: 0px;
  }
}

.card-text-dark {
  color: var(--primary-button-text-color-dark);

  @media (max-width: 900px) {
    color: var(--text-color-dark);
  }
}

.card-text-light {
  color: var(--primary-button-text-color-light);

  @media (max-width: 900px) {
    color: var(--text-color-light);
  }
}

.card-name {
  position: absolute;
  bottom: 0;
  font-weight: 600;
  font-size: 18px;
  transition: 0.5s;
  text-align: left;
  margin: 8px 20px;
  // text-shadow: 0px 0px 0 #000, 0px 0px 0 #000, 0px 0px 0 #000, 1px 1px 0 #002f27;

  @media (max-width: 900px) {
    position: inherit;
    text-decoration: underline;
    font-size: 16px;
    margin: 5px 0px;
  }
}

.card-info {
  position: absolute;
  pointer-events: none;
  top: 100%;
  font-size: 16px;
  margin-top: 0px;
  opacity: 0;
  transition: 0.5s;
  height: 80%;

  @media (max-width: 900px) {
    position: static;
    opacity: 1;
    font-size: 14px;
    line-height: 1.5rem;
    text-align: initial;
  }
}

.gradient-background:hover {

  cursor: pointer;
  border-radius: 10px;

  img {
    filter: blur(1px);
    transition: 1s ease-out;

    @media (max-width: 900px) {
      filter: none;
    }
  }

  .card-name {
    text-decoration: underline;
    bottom: 90%;
    transform: translateY(100%);

    @media (max-width: 900px) {
      transform: none;
    }
  }

  .card-info {
    top: 20%;
    opacity: 1;
  }

  &:after {
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(247, 252, 252, 0.0416667) 40%, rgb(7, 83, 135) 100%), rgba(4, 22, 61, 0.9);
    position:absolute;

    @media (max-width: 900px) {
      background: none;
      // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(247, 252, 252, 0.0416667) 60%, rgba(55,187,189,1) 100%);
    }
  }
}

.card-tag-list {
  position: inherit;
  display: flex;
  bottom: 15%;
}

.card-tag {
  border: 1px solid #FAFAFA;
  border-radius: 45px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  color: white;
}

.extra-info {
  @media (max-width: 900px) {
    display: none;
  }
}

.card-description {
  margin: 0px;
  margin-top: 10px;
  text-align: left;
  line-height: 24px;

  @media (max-width: 900px) {
    margin-top: 0px;
  }
}