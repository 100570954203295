// .welcome-overlay {
//     position: fixed;
//     background-color: #37BBBD;
//     width: 100%;
//     height: 100%;
//     opacity: 1;
//     z-index: 10;
// }

// .fade-out {
//     transition: 2s ease-in;
//     opacity: 0;
//     z-index: -5;
// }
.nav-bar{
    width: 100%;
    padding-top: var(--header-height);
}

.nav-bar-content{
    height: var(--header-height);
    width: 100%;
    min-height: 25px;
    top: 0;
    position: fixed;
    display: flex;
    z-index: 2;
}

.logo-container{
    width: auto;
    height: 100%;
    position: relative;
}

.links-container{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 750px) {
        display: none;
    }
}

.logo{
    height: 90%;
    max-width: 100%;
    left: 15%;
    position: absolute;
    object-fit: contain;
}

.link-title {
    margin-right: 40px;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    color: #FAFAFA;
}

.link-title:hover {
    text-decoration: underline;
}

.menu {
    align-items: center;
    margin-left: 3%;
    margin-right: 2%;
    display: none;

    @media (max-width: 750px) {
        display: flex;
    }
}

.menu-icon {
    color: white;
    font-size: 32px;
}

.menu-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: var(--header-height);
    background: #075387;
    z-index: 2;
}

.dropdown-link {
    padding: 25px;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    color: #FAFAFA;
}

.overlay {
    position: absolute;
    width: 100vh;
    height: 100vh;
    color: black;
    z-index: 1;
}

.nav-bar-logo-text{
    width: 150px;
    font-size: 20px;
    font-weight: 700;
    color: white;
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 40px;

    @media (max-width: 750px) {
        margin-left: 15px;
        font-size: 18px;
    }

}

.slider-container {
    display: flex;
    align-items: center;

    @media (max-width: 750px) {
        width: 100%;
        justify-content: right;
    }
}