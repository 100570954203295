.projects {
    top: 0px;
}

.header {
    width: 100%;
    display: flex;
}

.title {
    font-weight: bold;
    font-size: 36px;
    text-align: left;
}

.filters {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    width: 50%;
    font-size: 25px;
}

.filter-item {
    list-style-type:none;
    margin-left: 5px;
}

.projects-title-container {
    justify-self: center;
    @media (min-width: 900px) {
        width: 400px;
    }
}

.middle-padding-grid {
    @media (max-width: 940px) {
        // display: none;
    }
    @media (max-width: 900px) {
        // display: grid;
    }
}

.last-padding-grid {
    @media (max-width: 1400px) {
        display: none !important;
    }
    @media (max-width: 900px) {
        display: grid !important;
    }
    @media (max-width: 520px) {
        display: none !important;
    }
}

.projects-list {
    display: grid;
    grid-template-columns: auto auto auto;
    @media (max-width: 1400px) {
        grid-template-columns: auto auto;
    }
    // @media (max-width: 940px) {
    //     grid-template-columns: auto;
    // }
    @media (max-width: 900px) {
        grid-template-columns: auto auto auto;
    }
    @media (max-width: 520px) {
        grid-template-columns: auto auto;
    }



}

.item {
    margin: 20px 10px;
    place-self: center;
    display: flex;
    align-self: start;

    @media (max-width: 900px) {
        margin: 0px;
        margin-bottom: 35px;
    }
}
