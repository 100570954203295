.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    margin-right: 30px;
    user-select: none;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--slider-background-color-light);
    transition: 0.4s;
    border-radius: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
  }

  input:checked + .slider {
    background-color: var(--slider-background-color-dark);
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    // left: 3px;
    background-color: var(--slider-icon-color-light);
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider:before {
    transform: translateX(28px);
    background-color: var(--slider-icon-color-dark);
  }
  
  .icon {
    font-size: 18px;
    transition: opacity 0.4s ease;
  }
  
  .hidden {
    opacity: 0;
  }

  .moon {
    color: var(--slider-icon-color-dark);
  }

  .sun {
    color: var(--slider-icon-color-light);
  }
  
  