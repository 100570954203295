.home {
    text-align: start;
}

.home-section {
    padding: 60px 0px;

    @media (max-width: 900px) {
        padding: 40px 0px;
    }
}

.first-section {
    padding-top: 35px;
    padding-bottom: 60px;
    @media (max-width: 900px) {
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.headline {
    font-size: 48px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 3.2rem;

    @media (max-width: 900px) {
        text-align: center;
        font-size: 38px;
        line-height: 2.5rem;
    }

    @media (max-width: 450px) {
        font-size: 24px;
        line-height: 2.0rem;
    }
}

.headline-description {
    margin-top: 0px;
    font-weight: 600;
}

.home-cv {
    display: flex;
    margin-top: 40px;
}

.home-text {
    @media (max-width: 800px) {
        text-align: justify;
    }
}

.technology-background {
    height: 535px;


    @media (max-width: 1700px) {
        height: 535px;
    }

    @media (max-width: 1160px) {
        height: 600px;
    }

    @media (max-width: 900px) {
        height: 500px;
    }

    @media (max-width: 640px) {
        height: 550px;
    }

    @media (max-width: 550px) {
        height: 650px;
    }

    @media (max-width: 470px) {
        height: 700px;
    }

    @media (max-width: 400px) {
        height: 760px;
    }
}

.home-inline-list {
    padding: 0;
    display: flex;
    list-style-type: none;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 70px;
}

.projects-item {
    width: 50%;
    margin: 2%;
}

.last-project {
    @media (max-width: 1300px) {
        display: none;
    }
    @media (max-width: 900px) {
        display: inline;
    }

    @media (max-width: 500px) {
        display: none;
    }
}

.home-contact-item {
    margin-left: 25px;
    margin-right: 25px;

    @media (max-width: 800px) {
        margin: 5px
    }
}

.home-photo-container {
    margin-left: 80px;
    margin-bottom: 25px;

    @media (max-width: 900px) {
        margin-left: 0px;
    }
}